/* Colours */

/* Dark Mode */
body {
  background-color: #131313 !important;
  color: #e6e6e6 !important;
}

.navbar-dark {
  background-color: #131313;
}

.icon {
  color: #e0e0e0;
}

#about, #footer {
  background-color: rgb(56, 56, 56);
}

.collapse.show,
.collapsing {
  background-color: #131313;
  padding-bottom: 1em;
}

.horizontalRule {
  max-width: 90%;
  border-color: #e6e6e6;
}

.mainTitle {
  text-shadow: 0 0 5px white;
}

/* Light Mode */
body.light-mode {
  background-color: #ececec !important;
  color: #191919 !important;
}

.light-mode .icon {
  color: #1f1f1f;
}

.navbar-light {
  background-color: #ececec;
}

.light-mode #about, .light-mode #footer {
  background-color: rgb(199, 199, 199);
}

.light-mode .collapse.show,
.light-mode .collapsing {
  background-color: #ececec;
}

.light-mode .horizontalRule {
  border-color: #191919;
}

.light-mode .mainTitle {
  text-shadow: 0 0 5px black;
}

/* Layout */

.App {
  text-align: center;
}

.sectionPadding {
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.footerPadding {
  margin-top: 15vh;
}

#main {
  display: flex;
  align-items: center;
}

#footer h5{
  margin-top: .5rem;
}

#contactForm {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lists {
  list-style-type: none;
  padding-inline-start: 0px;
}

.lists > li {
  padding-bottom: 1rem;
}

 .resumeTitle {
  position: sticky;
  top: 4rem; 
 }

.profileImg {
  border-radius: 50%;
}

.projectImg {
  width: 100%;
  border-radius: 5px;
}

.fullHeight {
  height: 100vh;
}

.thin {
  font-weight: 100;
}

.medium {
  font-weight: 300;
}

#themeSwitch {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  min-width: 95px;
}

.mainTitle {
  font-size: 90px;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
}

html {
  scroll-padding-top: 70px; /* height of sticky header */
  scroll-behavior: smooth; /* smooth scroll for page links */
}

@media (max-width: 767px) {
  body .text-right {
    text-align: center !important;
  }
}
